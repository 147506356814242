<template>
  <section>
    <div class="col-md-12">
      <pm-Breadcrumb :home="home" :model="items_bread" class="hide-presenca" />
      <div class="hide-mob">
        <p style="text-align: center; font-size: 15px">
          <b v-for="b in items_bread">{{ b.label }} &nbsp</b>
        </p>
      </div>
      <br />
      <div class="form-elements">
        <div class="row">
          <div class="col-md-9 col-9">
            <div>
              <h2 class="titulo-links-escolha exibe-mobile-msg2">
                Presenças
                <va-popover
                  placement="right"
                  color="primary"
                  title="Informação do módulo atual"
                  message="Aqui o professor poderá lançar as presenças, faltas e justificativas de faltas de seus alunos!"
                >
                  <i
                    class="pi pi-question-circle"
                    style="font-size: 1.5rem"
                  ></i>
                </va-popover>
              </h2>
              <h2 class="titulo-links-escolha exibe-mobile-msg">Presenças</h2>
            </div>
          </div>

          <div class="col-md-3 col-3">
            <pm-Button
              type="button"
              style="float: right; margin-top: 5px"
              @click="trocarTurma()"
              label="Trocar de Turma"
              class="p-button-danger trocar-turma"
              icon="pi pi-angle-left"
              iconPos="left"
            ></pm-Button>
          </div>
        </div>
      </div>

      <p class="exibe-mobile-msg">
        Aqui o professor poderá lançar as presenças, faltas e justificativas de
        faltas de seus alunos!
      </p>
      <div class="p-fluid formgrid grid" v-if="!carregandoPagina">
        <div style="margin-top: 18px" class="field col-12">
          <div class="row col-md-12">
            <div class="col-md-12">
              <selecionarDisciplinasProfessor
                v-if="mostrarDisciplinas"
                @Config="selecionarMes"
                :disciplina="disciplinas"
                :infoVerificarPresenca="[]"
              />
            </div>
            <div class="col-md-12" v-if="tabelaDias.length > 0">
              <div style="background-color: white; border-radius: 5px">
                <div
                  style="
                    background-color: rgb(255, 255, 255);
                    border-radius: 5px;
                    padding: 20px;
                  "
                  class="field col-12"
                >
                  <selectCalendario2
                    @exibirTabela="exibirTabela"
                    @test="montarCalendario"
                    :mes_nome="this.Presenca.mesSelecionado"
                    :mes="this.Presenca.mes"
                    :tabelaDias="tabelaDias"
                    :mesNumero="this.Presenca.mes_numeral"
                    :diaSelecionado="this.Presenca.dia"
                    @dialogAction="dialogAction"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style="text-align: center">
          <pm-ProgressSpinner v-if="isLoadingPage" />
        </div>
        <div
          class="p-fluid formgrid grid"
          v-if="abrirComponete && !isLoadingPage"
        >
          <div
            class="field col-12"
            style="margin-top: 20px"
            v-if="this.presencasAlunosView"
          >
            <CalendarioVisualizar
              :key="montarCalendario"
              @test="montarCalendario"
              @abrirConteudo="abrirConteudo"
              @abrirTodosOsConteudo="abrirTodosOsConteudo"
              :index="maiorIndex"
              :acesso="acesso"
              :presencasAlunosView="presencasAlunosView"
              :tabelaDias="tabelaDias"
              :mes_nome="this.Presenca.mesSelecionado"
              :mes_sel="this.Presenca.mes"
              :mes="this.Presenca.mesSelecionado"
              :disciplina_id="disciplina_id"
              :disciplinas_fantasia_id="disciplinas_fantasia_id"
              :turma_id = "turma_id"
            />
          </div>
        </div>
      </div>

      <!--<pm-Dialog
        header="Enviar presença"
        v-model:visible="displayMaximizable"
        :style="{ width: '100vw' }"
        :maximizable="true"
        :modal="true"
      >
        <div class="field col-12" v-if="this.Presenca.dataSelecionada != ''">
          <div>
            <div class="row">
              <div class="col-md-12" v-if="this.Presenca.dataSelecionada">
                <h2 class="text-01-presenca">
                  <b>Data selecionada: {{ this.Presenca.dataSelecionada }}</b> -
                  envio de presença do dia para a disciplina de
                  <b>{{ this.disciplina_nome }}</b>
                </h2>
              </div>

              <div
                class="field col-12 md:col-3 lg:col-3"
                v-if="presenca_infinita == 1"
              >
                <label>Quantidade de presenças</label>
                <select
                  class="p-inputtext p-component"
                  v-model="qdt_presenca"
                  style="appearance: revert !important; width: 100% !important"
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>
              </div>
            </div>
            <div class="p-fluid formgrid grid">
              <div class="row">
                <div
                  class="row"
                  v-if="this.Presenca.dia"
                  style="margin-top: 5px"
                >
                  <h4 class="title-presenca-table">Alunos da Turma</h4>
                  <table-prof-presenca01
                    @marcarPresenca="marcarPresenca"
                    :alunos="alunos"
                    :acesso="acesso"
                    :st_segmento="this.Presenca.segmento_id"
                    :etapaDeEnvioDePresenca="etapaDeEnvioDePresenca"
                  />
                </div>

                <div
                  class="row"
                  v-if="this.Presenca.dia"
                  style="left: 0px; width: 133px"
                >
                  <va-inner-loading
                    class="py-3"
                    style="width: 100%"
                    :loading="isLoading"
                  >
                    <button
                      class="btn btn-primary"
                      @click="presenca()"
                      v-if="acesso.cadastrar && this.alunos.length"
                    >
                      Enviar Presença
                    </button>
                  </va-inner-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template #footer> </template>
      </pm-Dialog> -->

      <pm-Dialog
        header="Enviar presença"
        v-model:visible="displayMaximizable"
        :style="{ width: '100vw' }"
        :maximizable="true"
        :modal="true"
      >
        <div class="field col-12" v-if="this.Presenca.dataSelecionada != ''">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12" v-if="this.Presenca.dataSelecionada">
                  <h2 class="text-01-presenca">
                    <b>Data selecionada: {{ this.Presenca.dataSelecionada }}</b> -
                    envio de presença do dia para a disciplina de
                    <b>{{ this.disciplina_nome }}</b>
                  </h2>
                </div>

                <div
                  class="field col-12 md:col-3 lg:col-3"
                  v-if="presenca_infinita == 1"
                >
                  <label>Quantidade de presenças</label>
                  <select
                    class="p-inputtext p-component"
                    v-model="qdt_presenca"
                    style="appearance: revert !important; width: 100% !important"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </select>
                </div>
              </div>
              <div class="p-fluid formgrid grid">
                <div class="row">
                  <div
                    class="row"
                    v-if="this.Presenca.dia"
                    style="margin-top: 5px"
                  >
                    <h4 class="title-presenca-table">Alunos da Turma</h4>
                    <table-prof-presenca01
                      @marcarPresenca="marcarPresenca"
                      :alunos="alunos"
                      :acesso="acesso"
                      :st_segmento="this.Presenca.segmento_id"
                      :etapaDeEnvioDePresenca="etapaDeEnvioDePresenca"
                    />
                  </div>

                  <div
                    class="row"
                    v-if="this.Presenca.dia"
                    style="left: 0px; width: 133px"
                  >
                    <va-inner-loading
                      class="py-3"
                      style="width: 100%; position: fixed; bottom: 0; right: 28px; display: grid; justify-content: end;"
                      :loading="isLoading"
                      v-if="acesso.cadastrar && this.alunos.length"
                    >
                      <button
                        :disabled="!situacaoAno || isButtonDisabled"
                        class="btn btn-primary"
                        @click="presenca()"
                        v-if="conteudoObrigatorio == 1"
                      >
                        Enviar Presença
                      </button>
                      <button
                        class="btn btn-primary"
                        disabled
                        v-else
                      >
                        Enviar Presença
                      </button>
                    </va-inner-loading>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <h3>Conteúdo Aplicado</h3>
              <modalConteudoAplicado
                edicao="0"
                :dados="{
                  disc_fantasia_id: this.disciplinas_fantasia_id,
                  disciplina_id: this.disciplina_id,
                  data: this.Presenca.dataSelecionada,
                  turma_id: this.Presenca.turma_id,
                }"
                :mes_c="this.mes_c"
                :completo="0"
                @changeStatusModal="changeStatusModal"
                @saveAppliedContent ="saveAppliedContent"
                @conteudoObg ="conteudoObg"
              />
            </div>
          </div>
        </div>
        <template #footer> </template>
      </pm-Dialog>

      <pm-Dialog header="Conteúdo Aplicado" v-model:visible="displayModalConteudo" :style="{width: '100vw'}" :maximizable="true" :modal="true">
        <modalConteudoAplicado
          edicao="1"
          :dados="this.dados"
          :mes_c="this.mes_c"
          :completo="this.completo"
          @changeStatusModal="changeStatusModal"
          @saveAppliedContent ="saveAppliedContent"
        />
      </pm-Dialog>
    </div>
  </section>
</template>

<script>
import CalendarioVisualizar from "@/components/presenca/calendarioVisualizar.vue";

import tableProfPresenca01 from "@/components/presenca/tableProfPresenca01.vue";
import tableProfPresenca02 from "@/components/presenca/tableProfPresenca02.vue";
import selectCalendario from "@/components/presenca/selectCalendario.vue";
import selectCalendario2 from "@/components/presenca/selectCalendario2.vue";
import selecionarDisciplinasProfessor from "@/components/selecionarDisciplinasProfessor.vue";
import mostrarPresencaOuFalta from "@/components/presenca/mostrarPresencaOuFalta.vue";
import editarFundamental1 from "@/components/presenca/editarFundamental1.vue";
import editarFundamental2 from "@/components/presenca/editarFundamental2.vue";

import modalConteudoAplicado from "@/components/conteudo_aplicado/modal-conteudo-aplicado.vue";

import { PlanejamentoPedagogico } from "@/class/planejamentopedagogico";

import { defineComponent } from "vue";

import { Presenca } from "@/class/presenca";
import { Turma } from "@/class/turma";
import { Servidores } from "@/class/servidores";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { SeriesEscolar } from "@/class/serie.js";
import axios from "axios";

const meses_ano = [
  { numero: "01", palavra: "janeiro" },
  { numero: "02", palavra: "fevereiro" },
  { numero: "03", palavra: "marco" },
  { numero: "04", palavra: "abril" },
  { numero: "05", palavra: "maio" },
  { numero: "06", palavra: "junho" },
  { numero: "07", palavra: "julho" },
  { numero: "08", palavra: "agosto" },
  { numero: "09", palavra: "setembro" },
  { numero: "10", palavra: "outubro" },
  { numero: "11", palavra: "novembro" },
  { numero: "12", palavra: "dezembro" },
];

export default defineComponent({
  props: {
    id: {
      required: true,
    },
    turma_id: {
      required: true,
    },
    professor_id: {
      required: true,
    },
    turma_disciplina_id: {
      required: true,
    },
    segmento: {
      required: true,
    },
    dataSelecionada: {
      required: true,
    },
    escola_id: {
      required: true,
    },
    serie_id: {
      required: true,
    },
    turno: {
      required: true,
    },
  },
  components: {
    CalendarioVisualizar,
    tableProfPresenca01,
    tableProfPresenca02,
    selectCalendario,
    selectCalendario2,
    selecionarDisciplinasProfessor,
    mostrarPresencaOuFalta,
    editarFundamental1,
    editarFundamental2,
    modalConteudoAplicado
  },

  data() {
    return {
      displayModalConteudo:false,
      acesso: {
        visualizar: 1,
        cadastrar: 1,
        editar: 1,
        deletar: 1,
      },
      abrirComponete: false,
      carregandoPagina: false,
      isLoadingPage: false,
      isLoading: false,
      items_bread: [{ label: "" }],
      session_storage: [],
      segmento_tipo: -1,
      serie_tipo: 0,

      tabelaDias: [],
      disciplinas: [],
      disciplina_id: 2,
      disciplinas_fantasia_id: 0,
      disciplina_nome: "",

      alunos: [],

      displayMaximizable: false,
      mostrarDisciplinas: true,

      ensino_basico: 0,
      possui_mais_de_um_professor: 0,
      mesAtual: 0,
      Presenca: {
        disciplina_id: 0,
        mes: "",
        turma_id: "",
        segmento_id: "",
        serie: "",
        ano: sessionStorage.getItem("anoSelecionado"),
        semana: "",
        dataSelecionada: "",
        dia: "",
        mes_numeral: 0,
        mesSelecionado: "",
        mes_selec: "",
        dataSelecionaFormatoEUA: "",
      },

      etapaDeEnvioDePresenca: 0,

      qdt_presenca: 1,
      presenca_infinita: 0,
      editarOk: false,
      presencasAlunosView: [],
      tabelaDiasApenasAnosLetivoColunas: [],
      tabelaPresencasColunas: [],
      maiorIndex: 0,
      todosOsAlunos: [],
      alunosPresente: [],
      dados:[],
      mes_c:"",
      completo:0,
      conteudoAplicado:[],
      conteudoObrigatorio:0,
      nVazio:[],
      nV:0,
      isButtonDisabled: 0,
      situacaoAno:0,
    };
  },

  async beforeMount() {
    this.carregandoPagina = true;
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
    if (this.id == null || this.turma_id == null) {
      this.session_storage.segmento =
        sessionStorage.getItem("Professor_segmento");
      this.session_storage.serie_id =
        sessionStorage.getItem("Professor_serie_id");
      this.session_storage.id = sessionStorage.getItem("Professor_id");
      this.session_storage.turma_id =
        sessionStorage.getItem("Professor_turma_id");
      this.session_storage.professor_id = sessionStorage.getItem(
        "Professor_professor_id"
      );
      this.session_storage.turma_disciplina_id = sessionStorage.getItem(
        "Professor_turma_disciplina_id"
      );
      this.session_storage.escola_id = sessionStorage.getItem(
        "Professor_escola_id"
      );
      this.session_storage.turno = sessionStorage.getItem("Professor_turno");
      this.session_storage.dataSelecionada = sessionStorage.getItem(
        "Professor_dataSelecionada"
      );
    } else {
      this.session_storage.segmento = this.segmento;
      this.session_storage.serie_id = this.serie_id;
      this.session_storage.id = this.id;
      this.session_storage.turma_id = this.turma_id;
      this.session_storage.professor_id = this.professor_id;
      this.session_storage.turma_disciplina_id = this.turma_disciplina_id;
      this.session_storage.escola_id = this.escola_id;
      this.session_storage.turno = this.turno;
      this.session_storage.dataSelecionada = this.dataSelecionada;
    }

    this.Presenca.turma_id = this.session_storage.turma_id;
    this.Presenca.segmento_id = this.session_storage.segmento;
    this.Presenca.serie = this.session_storage.serie_id;

    this.getQdtPresenca();

    await this.segmentoObtemUm(this.session_storage.segmento);
    await this.serieObtemUm(this.session_storage.serie_id);

    const turma = {
      turma_id: this.session_storage.turma_id,
      etapa_id: this.session_storage.segmento,
      periodo_id: this.session_storage.serie_id,
      escola_id: this.session_storage.escola_id,
    };
    const resp_turma = await Turma.aturma(turma);
    this.items_bread = [
      { label: resp_turma.data[0].escola },
      { label: resp_turma.data[0].etapa },
      { label: resp_turma.data[0].periodo },
      { label: resp_turma.data[0].turma },
      { label: resp_turma.data[0].turno },
    ];

    this.disciplinasPresenca();

    this.carregandoPagina = false;

    await this.turmaAlunos();
  },

  methods: {
    conteudoObg(valor){
      this.conteudoObrigatorio = valor;
    },
    changeStatusModal(val){
      if(val == 1){
       this.displayModalConteudo = false
      }else{
        this.displayModalConteudo = true
      }
    },
    async abrirTodosOsConteudo(mes,it){
      this.mes_c = "";
      this.mes_c = mes;
      this.completo = 1;
      this.dados = it;
      this.displayModalConteudo = true;
    },
    async abrirConteudo(mes,it){
      //console.log(it)
      console.log(it)
      this.mes_c = "";
      this.mes_c = mes;
      this.completo = 0;
      this.dados = [];
      this.dados = it;
      this.displayModalConteudo = true;
    },
    trocarTurma() {
      this.$router.push({ name: "turmas-Professor" });
    },
    marcarPresenca(n, item) {
      item.presente = n;
    },
    marcarPresencaFundamentalDois(chamada, n, item) {
      if (chamada == 1) {item.presenca_1 = n;}
      else {item.presenca_2 = n;}
    },

    async segmentoObtemUm(id) {
      const data = await SegmentoEscolar.obtemUm(id);
      this.segmento_tipo = data.data.tipo;
    },

    async getQdtPresenca() {
      const data = await axios.get(
        "/config/sistema/presenca/" +
          this.session_storage.segmento +
          "/segmento"
      );
      this.presenca_infinita = data.data.presenca_infinita;
    },

    async serieObtemUm(id) {
      const data = await SeriesEscolar.obtemUm(id);
      this.possui_mais_de_um_professor = data.data.possui_mais_de_um_professor;
      if (this.segmento_tipo != 2) {
        this.serie_tipo = data.data.tipo;
      } else {
        this.serie_tipo = 0;
      }
    },

    async disciplinasPresenca() {
      const playload = {
        turma_id: this.session_storage.turma_id,
        professor_id: this.session_storage.professor_id,
      };

      const res = await Servidores.ProfessorPrincipalShow(playload);

      if (res.data.disc_fantasia_id > 0) {
        this.ensino_basico = 1;
        this.disciplinas_fantasia_id = res.data.disc_fantasia_id;
        this.disciplina_id = 1;
        this.mostrarDisciplinas = false;
        this.disciplina_nome = res.data.disc_fantasia_nome;
        this.selecionarMes({});
      } else {
        this.ensino_basico = 0;
        if (this.segmento_tipo > 1) {
          if (this.segmento_tipo < 3) {
            const data = await Turma.obtemTurmaProfessorV2(playload);
            this.disciplinas = data.data;
            this.mostrarDisciplinas = true;
          } else {
            if (this.serie_tipo > 1) {
              const data = await Turma.obtemTurmaProfessorV2(playload);
              this.disciplinas = data.data;
              this.mostrarDisciplinas = true;
            } else {
              this.disciplina_id = 1;
              this.mostrarDisciplinas = false;
              this.selecionarMes({});
              this.disciplina_nome = " Todas as disciplinas";
            }
          }
        } else if (this.segmento_tipo === 1) {
          if (this.possui_mais_de_um_professor === 1) {
            const data = await Turma.obtemTurmaProfessorV2(playload);
            this.disciplinas = data.data;
            this.mostrarDisciplinas = true;
          } else {
            this.disciplina_id = 1;
            this.mostrarDisciplinas = false;
            this.selecionarMes({});
            this.disciplina_nome = " Todas as disciplinas";
          }
        } else {
          this.mostrarDisciplinas = false;
          this.selecionarMes({});
          this.disciplina_nome = " Educação Infantil";
        }
      }
    },

    async selecionarMes(n) {
      if (typeof n !== "undefined") {
        if (typeof n.disciplina !== "undefined") {
          this.disciplina_nome = n.disciplina;
          this.disciplina_id = n.disciplina_id;
        }
      }

      const date = new Date();
      const currentMonth = date.getMonth() + 1;
      this.mesAtual = String(currentMonth);

      if (this.mesAtual.length == 1) {this.mesAtual = "0" + this.mesAtual;}
      if (currentMonth == 1) {
        await this.montarCalendario("janeiro", "Janeiro");
      }
      if (currentMonth == 2) {
        await this.montarCalendario("fevereiro", "Fevereiro");
      }
      if (currentMonth == 3) {
        await this.montarCalendario("marco", "Março");
      }
      if (currentMonth == 4) {
        await this.montarCalendario("abril", "Abril");
      }
      if (currentMonth == 5) {
        await this.montarCalendario("maio", "Maio");
      }
      if (currentMonth == 6) {
        await this.montarCalendario("junho", "Junho");
      }
      if (currentMonth == 7) {
        await this.montarCalendario("julho", "Julho");
      }
      if (currentMonth == 8) {
        await this.montarCalendario("agosto", "Agosto");
      }
      if (currentMonth == 9) {
        await this.montarCalendario("setembro", "Setembro");
      }
      if (currentMonth == 10) {
        await this.montarCalendario("outubro", "Outubro");
      }
      if (currentMonth == 11) {
        await this.montarCalendario("novembro", "Novembro");
      }
      if (currentMonth == 12) {
        await this.montarCalendario("dezembro", "Dezembro");
      }
    },

    async montarCalendario(mes, mesExibir) {
      this.isLoadingPage = true;
      this.abrirComponete = false;

      this.Presenca.mes = mes;

      //receber mês número
      const found = meses_ano.find((element) => element.palavra == mes);
      this.Presenca.mes_numeral = found.numero;

      this.Presenca.mesSelecionado = mesExibir;

      this.tabelaDias = [];
      this.presencasAlunosView = [];
      this.Presenca.dia = "";
      this.Presenca.semana = "";
      this.Presenca.dataSelecionada = "";
      this.Presenca.disciplina_id = this.disciplina_id;

      async function calendarioProfessor(presenca, disciplinas_fantasia_id) {
        if (disciplinas_fantasia_id < 1) {
          const res = await Presenca.presencaMontarCalendarioProfessor(
            presenca
          );
          return res.data;
        } else {
          const res =
            await Presenca.presencaMontarCalendarioProfessorDiscFantasia(
              presenca,
              disciplinas_fantasia_id
            );
          return res.data;
        }
      }

      await calendarioProfessor(this.Presenca, this.disciplinas_fantasia_id)
        .then((res) => {
          this.tabelaDias = res;
        })
        .catch((e) => {
          console.log(e);
          setTimeout(() => {
            this.$vaToast.init({
              message: e.response.data.erro,
              iconClass: "fa-star-o",
              position: "top-right",
              duration: 3500,
              fullWidth: false,
              color: "danger",
            });
          }, 50);
        })
        .finally(() => {
          this.tabelaDiasApenasAnosLetivoColunas = [];
          for (let i = 0; i < this.tabelaDias.length; i++) {
            if (this.tabelaDias[i].letivo == "0") {continue;}
            if (this.tabelaDias[i].dia.length == 1)
              {this.tabelaDias[i].dia = "0" + this.tabelaDias[i].dia;}
            this.tabelaDiasApenasAnosLetivoColunas.push(this.tabelaDias[i]);
          }
        });

      async function presencaMensal(
        disciplina_id,
        mes,
        turma_id,
        anoSelect,
        data_inicial_matricula,
        data_final_matricula,
        disciplinas_fantasia_id
      ) {
        //presencas visu
        const res = {
          disciplina_id: disciplina_id,
          mes: mes,
          turma_id: turma_id,
          ano: anoSelect,
        };
        const data1 = await Presenca.mesesVisu(res, disciplinas_fantasia_id);
        const arr = [];

        for (const el of data1.data) {
          const info = {
            id: el.aluno.id,
            nome: el.aluno.nome + " " + el.aluno.sobrenome,
            evento_saida: el.evento_saida,
            presencas: [],
            cntPresencas: 0,
            data_entrada_turma: el.data_entrada_na_turma,
            data_entrada_turma_br: el.data_entrada_na_turma_br,
            entrou_no_prazo_certo: el.entrou_no_prazo_certo,
            status: el.status,
            transferido: el.transferido,
            saiu_da_turma_data: el.saiu_da_turma_data,
            entrou_na_turma_por_transferencia:
              el.entrou_na_turma_por_transferencia,
            saiu_da_turma_data_formato_br: el.saiu_da_turma_data_formato_br,
            entradaESaidaDaTurma: el.entradaESaidaDaTurma,
            historico_saida_entrada_turma: el.historico_saida_entrada_turma,
            turma_id: el.turma_id,
          };

          for (const el1 of el.presenca) {
            const novo = {
              presenca_id: el1.id,
              aluno_id: el.aluno.id,
              data: el1.data,
              disciplina_id: el1.disciplina_id,
              escola_id: el1.escola_id,
              id_presenca: el1.id,
              justificacao_conteudo: el1.justificacao_conteudo,
              justificada_falta: el1.justificada_falta,
              presenca: el1.presenca,
              professor_id: el1.professor_id,
              segmento_id: el1.segmento_id,
              serie_id: el1.serie_id,
              turma_id: el1.turma_id,
              disc_fantasia_id: el1.disc_fantasia_id,
              dia: "",
            };
            //contar presencas
            info.cntPresencas += el1.presenca;
            //receber dia
            let receberDia = novo.data.split("-");
            receberDia = receberDia[2].split(" ");
            novo.dia = receberDia[0];
            info.presencas.push(novo);
          }
          arr.push(info);
        }
        return arr;
      }

      await presencaMensal(
        this.Presenca.disciplina_id,
        mes,
        this.Presenca.turma_id,
        this.Presenca.ano,
        this.data_inicial_matricula,
        this.data_final_matricula,
        this.disciplinas_fantasia_id
      )
        .then((res) => {
          this.presencasAlunosView = res;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          let maior = 0;
          for (let i = 0; i < this.presencasAlunosView.length; i++) {
            if (this.presencasAlunosView[i].presencas.length > maior) {
              maior = this.presencasAlunosView[i].presencas.length;
              this.maiorIndex = i;
            }
          }

          this.tabelaPresencasColunas = [];
          this.criarTabelaDePresencasParaCadaAluno(
            this.receberTodosOsDiasQueExistemPresencas()
          );
          this.presencasAlunosView[0].tableColuna = this.tabelaPresencasColunas;
          this.isLoadingPage = false;
        });

      this.carregandoPagina = false;
      this.abrirComponete = true;
      return true;
    },

    criarTabelaDePresencasParaCadaAluno(mapOfValues) {
      //Para exibir os dados na tabela de presenca
      for (var i = 0; i < this.presencasAlunosView.length; i++) {
        this.presencasAlunosView[i].presencas1 = [];
      }
      for (const dia of mapOfValues) {
        var presencasDoAluno = this.todasAsPresencasQueExistemNoDia(dia);
        for (var i = 0; i < this.presencasAlunosView.length; i++) {
          for (var j = 0; j < presencasDoAluno.length; j++) {
            //Verificar se o aluno i possue a presenca j
            const found = this.presencasAlunosView[i].presencas.find(
              (element) => element.data == presencasDoAluno[j].data
            );

            if (found != undefined) {
              this.presencasAlunosView[i].presencas1.push(found);
            } else {
              console.log(" dawdaw: "+ presencasDoAluno[j])
              const aluno = {
                data: presencasDoAluno[j].data,
                disciplina_id: presencasDoAluno[j].disciplina_id,
                disc_fantasia_id: presencasDoAluno[j].disc_fantasia_id,
                escola_id: presencasDoAluno[j].escola_id,
                segmento_id: presencasDoAluno[j].segmento_id,
                serie_id: presencasDoAluno[j].serie_id,
                professor_id: presencasDoAluno[j].professor_id,
                turma_id: presencasDoAluno[j].turma_id,
              };
              this.presencasAlunosView[i].presencas1.push(aluno);
            }
          }
        }
      }
    },
    //Receber todos os dia que possuem presencas
    receberTodosOsDiasQueExistemPresencas() {
      const myPresencasDias = new Set();
      for (let i = 0; i < this.presencasAlunosView.length; i++) {
        for (let j = 0; j < this.presencasAlunosView[i].presencas.length; j++) {
          if (
            !myPresencasDias.has(this.presencasAlunosView[i].presencas[j].dia)
          ) {
            myPresencasDias.add(this.presencasAlunosView[i].presencas[j].dia);
          }
        }
      }
      return Array.from(myPresencasDias).sort((a, b) => a - b);
    },
    //Buscar todas as presencas que existem em um dia X
    todasAsPresencasQueExistemNoDia(dia) {
      const mySet = new Set();
      const todasAsPresencasNoDia = [];
      for (let i = 0; i < this.presencasAlunosView.length; i++) {
        for (let j = 0; j < this.presencasAlunosView[i].presencas.length; j++) {
          if (
            !mySet.has(this.presencasAlunosView[i].presencas[j].data) &&
            dia == this.presencasAlunosView[i].presencas[j].dia
          ) {
            todasAsPresencasNoDia.push(
              this.presencasAlunosView[i].presencas[j]
            );
            mySet.add(this.presencasAlunosView[i].presencas[j].data);
            //Para add dias para criar as colunas da tabela
            this.tabelaPresencasColunas.push(dia);
          }
        }
      }
      return todasAsPresencasNoDia.sort(function (a, b) {
        return a.presenca_id - b.presenca_id;
      });
    },
    async exibirTabela(dia, semana, mes, mesLetra) {
      this.editarOk = false;
      this.Presenca.dia = dia;
      //this.Presenca.mes = mesLetra;
      this.Presenca.semana = semana;
      if (String(dia).length == 1) {dia = "0" + dia;}

      this.Presenca.dataSelecionada =
        String(dia).padStart(2, "0") +
        "/" +
        mes.padStart(2, "0") +
        "/" +
        this.Presenca.ano;
      this.Presenca.dataSelecionaFormatoEUA =
        this.Presenca.ano + "-" + mes + "-" + String(dia).padStart(2, "0");
    },

    async turmaAlunos() {
      this.todosOsAlunos = [];
      const data = await Turma.obtemAlunosTurma(this.session_storage.turma_id);
      this.todosOsAlunos = data.data;
    },

    dialogAction() {
      this.alunos = [];
      for (const el of this.todosOsAlunos) {
        let dataDeEntradaNaTurma = el.created_at.split("T");
        dataDeEntradaNaTurma = dataDeEntradaNaTurma[0].substring(0, 10);
        //Verificar se a data que o aluno entrou na turma é menor ou igual a data selecionada. E, se o aluno foi transferido, se a data que foi transferido já chegou. Se chegou nao pode mais lancar presenca
        if (
          dataDeEntradaNaTurma <= this.Presenca.dataSelecionaFormatoEUA &&
          (el.saiu_da_turma_data == null ||
          this.Presenca.dataSelecionaFormatoEUA < el.saiu_da_turma_data)
        ) {
          this.alunos.push(el);
        }
      }
      if (!this.alunos.length) {
        this.$vaToast.init({
          message: "A turma não tinha alunos na data selecionada!",
          iconClass: "fa-star-o",
          position: "top-right",
          color: "warning",
          duration: 3000,
          fullWidth: false,
        });
      } else {
        this.displayMaximizable = true;
      }
      // this.displayMaximizable = true;
    },

    async saveAppliedContent(conteudo, envio){
      for (let i = 0; i < conteudo.length; i++) {
        if(conteudo[i].texto.length > 0 ){
          this.nVazio[i] = 1;
        }else{
          this.nVazio[i] = 0;
        }
      }

      let sum = 0;
      for (let i = 0; i < conteudo.length; i++) {
        sum+=this.nVazio[i];
      }

      if(sum == 0){
        sessionStorage.setItem("conteudoObrigatorio", 0);
        this.conteudoObg(0);
      }

      if(envio === 1){
        // salva o conteudo na Api
        for (let i = 0; i < conteudo.length; i++) {
          await PlanejamentoPedagogico.addPlanejamentoProf(conteudo[i]);
        }
      }else{
        // guarda em variavel
        this.conteudoAplicado = [];
        this.conteudoAplicado = conteudo
      }
    },

    async presenca() {
      try {
        this.isButtonDisabled = true;
        this.isLoadingPage = true;

        this.alunosPresente = [];
        //array dos alunos
        for (let i = 0; i < this.alunos.length; i++) {
          if (typeof this.alunos[i].presente === "undefined") {
            alert("Adicione as informações de presença para todos os alunos!");
            this.isLoadingPage = false;
            return false;
          }
          const novo = {
            aluno_id: this.alunos[i].id,
            presenca: this.alunos[i].presente,
            justificada_falta: 0,
            justificacao_conteudo: "",
          };
          this.alunosPresente.push(novo);
        }

        //2022-05-26 10:38:11
        //Receber a hora atual
        const now = new Date();
        let horas = String(now.getHours());
        let minutos = String(now.getMinutes());
        let segundos = String(now.getSeconds());
        if (horas.length == 1) {horas = "0" + horas;}
        if (minutos.length == 1) {minutos = "0" + minutos;}
        if (segundos.length == 1) {segundos = "0" + segundos;}

        const horaAtual = horas + ":" + minutos + ":" + segundos;

        //Data selecionada pelo usuário
        const dataFormatoUSA = this.Presenca.dataSelecionada.split("/");
        const dataAtual =
          dataFormatoUSA[2] + "-" + dataFormatoUSA[1] + "-" + dataFormatoUSA[0];
        const dataEHora = dataAtual + " " + horaAtual;
        this.displayMaximizable = false;

        async function criarPresenca(
          alunosPresente,
          segmentoInfo,
          escola_id,
          turma_id,
          serieInfo,
          disciplina_id,
          dataEHora,
          qdt_presenca,
          disciplinas_fantasia_id
        ) {
          const info = {
            segmento_id: segmentoInfo,
            escola_id: escola_id,
            turma_id: turma_id,
            serie_id: serieInfo,
            professor_id: 0,
            disciplina_id: disciplina_id,
            data: dataEHora,
            qdt_presenca: qdt_presenca,
          };

          let data;
          if (disciplinas_fantasia_id < 1) {
            data = await Presenca.criarPresencaDosAlunos(alunosPresente, info);
          } else {
            info.disc_fantasia_id = disciplinas_fantasia_id;
            data = await Presenca.criarPresencaDosAlunos(alunosPresente, info);
          }
          return data;
        }

        //this.turmaAlunos();
        this.montarCalendario(this.Presenca.mes, this.Presenca.mesSelecionado);

        criarPresenca(
          this.alunosPresente,
          this.session_storage.segmento,
          this.session_storage.escola_id,
          this.session_storage.turma_id,
          this.session_storage.serie_id,
          this.disciplina_id,
          dataEHora,
          this.qdt_presenca,
          this.disciplinas_fantasia_id
        )
          .then((res) => {
            this.qdt_presenca = 1;
            this.$vaToast.init({
              message: "Presença enviada com sucesso!",
              iconClass: "fa-star-o",
              position: "top-right",
              color: "success",
              duration: 3000,
              fullWidth: false,
            });

            this.conteudoap.date = dataEHora;
            this.conteudoap.turma_id = this.session_storage.turma_id
            this.displayModalConteudo = true
          })
          .catch((e) => {
            this.$vaToast.init({
              message: e.response.data.erro,
              iconClass: "fa-star-o",
              position: "top-right",
              color: "danger",
              duration: 3000,
              fullWidth: false,
            });
          })
          .finally(() => {
            this.isLoading = false;
            this.isButtonDisabled = false;
          });
      } catch (e) {
        this.$vaToast.init({
          message: "Error ao enviar presença!",
          iconClass: "fa-star-o",
          position: "top-right",
          color: "danger",
          duration: 3000,
          fullWidth: false,
        });
      }
      this.saveAppliedContent(this.conteudoAplicado, 1)
      this.isLoading = false;
      this.isButtonDisabled = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
.cabecario-turma {
  background-color: #4da4e9;
  color: #fff;
  margin-right: 10px;
  padding: 8px;
  border-radius: 5px;
  text-transform: uppercase;
}
@media (max-width: 500px) {
  .titulo-links-escolha {
    font-size: 24px;
    margin-top: 12px;
  }
}
</style>

<style scoped>
.th_per1 {
  background-color: #f8f9fa !important;
  color: #495057 !important;
  border-right: 1px solid #e9ecef !important;
  border-bottom: 2px solid #e9ecef !important;
  text-align: center;
  padding: 1rem 1rem;
  font-weight: 600;
}
.th_per2 {
  background-color: #3f5287;
  color: #fff;
  border-right: 1px solid #fff;
  border-bottom: 2px solid #fff;
  text-align: center;
  font-weight: 500;
}
.th_pn_aluno {
  width: 15%;
}
@media (max-width: 720px) {
  .th_pn_aluno {
    width: 90%;
  }
}

@media (max-width: 500px) {
  .cabecario-turma {
    display: inline-grid;
    margin-bottom: 5px;
    font-size: 12px;
  }
}

.col-icon-univer {
  padding: 0px;
  padding-top: 0px;
  padding-left: 10px;
  width: 4%;
}
@media (max-width: 500px) {
  .col-icon-univer {
    width: 15%;
  }
  .col-cturm {
    width: 80%;
  }
}

.escolha-nome-disciplina {
  font-size: 22px;
  margin-top: 10px;
  padding-bottom: 15px;
}

@media (max-width: 500px) {
  .escolha-nome-disciplina {
    font-size: 16px;
  }
}

.semana {
  background-color: rgb(239 239 239);
  text-align: center;
}
.font-semana {
  font-size: 10px;
}
.dias {
  text-align: center;
}
.dia-marcado {
  background-color: #4182c5 !important;
  color: #fff;
}
</style>
